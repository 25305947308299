import React, { useMemo } from "react"
import { graphql } from "gatsby"
import type { HeadFC } from "gatsby"
import { Search, SEO } from "../components"
import { DefaultLayout } from "../layouts"
import { PostNodes, MarkdownPostInterface } from "../typings/post.interface"
import { PostFormat } from "../lib/post-format"

const PostsPage = ({ data, ...props }: { data: { articles: PostNodes } }) => {
    const { articles } = data

    const formattedArticles: MarkdownPostInterface[] = useMemo(
        () => PostFormat(articles.nodes),
        [articles.nodes]
    )

    return (
        <>
            <DefaultLayout>
                <div className="text-center my-10">
                    <h1 className="font-inconsolata text-4xl leading-tight md:leading-normal">
                        Posts
                    </h1>
                    <p className="mb-5 font-inconsolata">
                        Writeup, artikel, dan informasi lainnya.
                    </p>
                </div>
                <div className="mb-6">
                    <Search posts={formattedArticles} {...props} />
                </div>
            </DefaultLayout>
        </>
    )
}

export const PostsQuery = graphql`
    query PostsQuery {
        articles: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { draft: { eq: false } } }
        ) {
            nodes {
                id
                frontmatter {
                    title
                    slug
                    date(formatString: "DD MMMM YYYY", locale: "id-ID")
                    description
                }
            }
        }
    }
`

export default PostsPage
export const Head: HeadFC = () => <SEO title="Posts" />
