import { MarkdownPostInterface } from "../typings/post.interface"

type PostFormatData = {
    id: string
    frontmatter: MarkdownPostInterface
}

export function PostFormat(markdown: PostFormatData[]) {
    return markdown.map((post: PostFormatData) => ({
        id: post.id,
        title: post.frontmatter.title,
        date: post.frontmatter.date,
        slug: "/" + post.frontmatter.slug,
        description: post.frontmatter.description,
    }))
}
